import React, { useState } from "react";
import { functions } from "../../firebase-config";
import { httpsCallable } from "firebase/functions";
import { useParams } from "react-router-dom";
import "./payTabs.css"
const PayTabsForm = () => {
  const {id} = useParams()
  const amount = +id
  const [formData, setFormData] = useState({
    customerEmail: "",
    customerName: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    amount: amount,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log(amount);
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const processPayment = httpsCallable(functions, "processPayment");

    try {
      const result = await processPayment(formData);
      const { url } = result.data;

      // Redirect the user to the PayTabs payment page
      window.location.href = url;
    } catch (err) {
      setError("Payment processing failed. Please try again.");
      console.error("Error processing payment:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="paytabs-form">
      <h2>Make a Payment</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="customerEmail"
          placeholder="Email"
          value={formData.customerEmail}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="customerName"
          placeholder="Full Name"
          value={formData.customerName}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="street"
          placeholder="Street Address"
          value={formData.street}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="city"
          placeholder="City"
          value={formData.city}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="state"
          placeholder="State"
          value={formData.state}
          onChange={handleChange}
        />
                <input
          type="text"
          name="country"
          placeholder="Country"
          value={formData.country}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="zip"
          placeholder="ZIP Code"
          value={formData.zip}
          onChange={handleChange}
        />

        <button type="submit" disabled={loading}>
          {loading ? "Processing..." : "Pay Now"}
        </button>
      </form>
    </div>
  );
};

export default PayTabsForm;
