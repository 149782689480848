import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionsData } from "../../store/SessionsSlice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./session.css";
const Session = () => {
  const { i18n, t } = useTranslation();
  const [changeName, setChangeName] = useState(false);

  const { Sessions } = useSelector((state) => state.Sessions);
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.language === "ar" ? setChangeName(true) : setChangeName(false);
  }, [i18n.language]);
  useEffect(() => {
    dispatch(SessionsData());
  }, [dispatch(SessionsData)]);

  return (
    <div className="session">
      <div className="container">
        <div className="session-banner"></div>
        {Sessions.map((el, i) => {
          return (
            <div key={i} className="session-box">
              <div className="session-img">
                <img src={el.img} alt="flipside" />
              </div>
              <div
                className="session-text"
                style={
                  changeName
                    ? { borderRight: "2px solid black" , padding:"20px 40px" }
                    : { borderLeft: "2px solid black" , padding:"20px 40px" }
                }
              >
                <h2> {changeName ? el.nameAr : el.nameEng} </h2>
                <h5> {el.sessionway} </h5>
                <p style={{ width: "400px" }}>
                  {changeName ? el.test : el.text}
                </p>
                <Link to={`/pay/${el.price}`}> {t("book")} </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Session;
