
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";

export const SessionsData = createAsyncThunk("session/fetch", async () => {
  try {
    const collectionRef = collection(db, "Sessions");
    const querySnapshot = await getDocs(collectionRef);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    return data;
  } catch (error) {
    throw error;
  }
});

const SessionsSlice = createSlice({
  name: "data",
  initialState: {
    Sessions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SessionsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SessionsData.fulfilled, (state, action) => {
        state.loading = false;
        state.Sessions = action.payload;
      })
      .addCase(SessionsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default SessionsSlice.reducer;
