
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions} from "firebase/functions";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyB-pl7gCaTfWoTTtVwsUOyfjw00et5AcxE",
  authDomain: "flipside-e458a.firebaseapp.com",
  projectId: "flipside-e458a",
  storageBucket: "flipside-e458a.appspot.com",
  messagingSenderId: "994362778079",
  appId: "1:994362778079:web:b92b5b21f61a64e5e8a46a",
  measurementId: "G-00J60VL700"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
