import { configureStore } from "@reduxjs/toolkit";
import programReducer from "./programSlice";
import podcastReducer from "./podcastSlice";
import SessionsReducer from "./SessionsSlice";
const store = configureStore({
  reducer: {
    data: programReducer,
    podcasts: podcastReducer,
    Sessions:SessionsReducer
  },
});

export default store;
