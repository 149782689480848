import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const FlipsideAnimation = () => {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoad(true); // تغيير الحالة إلى خاطئة بعد مرور فترة زمنية معينة
    }, 2000); // تغيير الحالة بعد ثانية واحدة

    return () => clearTimeout(timeout); // تنظيف الـ timeout عند إزالة المكون
  }, []);
  const controls = useAnimation();

  const variants = {
    hidden: { opacity: 0, y: -100, rotateY: 180 },
    visible: {
      opacity: 1,
      y: 0,
      rotateY: 0,
      transition: {
        duration: 0.3,
        type: "spring",
        stiffness: 200,
      },
    },
    hover: { scale: 1.1 },
    tap: { scale: 0.9 },
  };

  const textVariants = {
    hidden: { opacity: 0, y: -100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.3,
        type: "spring",
        stiffness: 150,
      },
    },
    hover: { scale: 1.1 },
    tap: { scale: 0.9 },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      className="flipsideanimtion"
    >
      {load ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          width="200"
          height="200"
          style={{ shapeRendering: "auto", display: "block" }}
          // xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g>
            <circle fill="#282828" r="10" cy="50" cx="84">
              <animate
                begin="0s"
                keySplines="0 0.5 0.5 1"
                values="10;0"
                keyTimes="0;1"
                calcMode="spline"
                dur="1s"
                repeatCount="indefinite"
                attributeName="r"
              ></animate>
              <animate
                begin="0s"
                values="#282828;#f4f0e7;#ddd9ce;#9b907e;#282828"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="discrete"
                dur="4s"
                repeatCount="indefinite"
                attributeName="fill"
              ></animate>
            </circle>
            <circle fill="#282828" r="10" cy="50" cx="16">
              <animate
                begin="0s"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                values="0;0;10;10;10"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                dur="4s"
                repeatCount="indefinite"
                attributeName="r"
              ></animate>
              <animate
                begin="0s"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                values="16;16;16;50;84"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                dur="4s"
                repeatCount="indefinite"
                attributeName="cx"
              ></animate>
            </circle>
            <circle fill="#9b907e" r="10" cy="50" cx="50">
              <animate
                begin="-1s"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                values="0;0;10;10;10"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                dur="4s"
                repeatCount="indefinite"
                attributeName="r"
              ></animate>
              <animate
                begin="-1s"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                values="16;16;16;50;84"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                dur="4s"
                repeatCount="indefinite"
                attributeName="cx"
              ></animate>
            </circle>
            <circle fill="#ddd9ce" r="10" cy="50" cx="84">
              <animate
                begin="-2s"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                values="0;0;10;10;10"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                dur="4s"
                repeatCount="indefinite"
                attributeName="r"
              ></animate>
              <animate
                begin="-2s"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                values="16;16;16;50;84"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                dur="4s"
                repeatCount="indefinite"
                attributeName="cx"
              ></animate>
            </circle>
            <circle fill="#cdc7bb" r="10" cy="50" cx="16">
              <animate
                begin="-3s"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                values="0;0;10;10;10"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                dur="4s"
                repeatCount="indefinite"
                attributeName="r"
              ></animate>
              <animate
                begin="-3s"
                keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                values="16;16;16;50;84"
                keyTimes="0;0.25;0.5;0.75;1"
                calcMode="spline"
                dur="4s"
                repeatCount="indefinite"
                attributeName="cx"
              ></animate>
            </circle>
            <g></g>
          </g>
        </svg>
      ) : (
        <motion.h1
          style={{
            fontSize: "4rem",
            cursor: "pointer",
            perspective: "1000px",
          }}
          variants={variants}
          initial="hidden"
          animate="visible"
          whileHover="hover"
          whileTap="tap"
          onClick={() => controls.start({ rotateY: 360 })}
        >
          <motion.span>
            <motion.span
              style={{
                display: "inline-block",
                originX: 0,
              }}
              variants={textVariants}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              whileTap="tap"
            >
              F
            </motion.span>
            lipSide
          </motion.span>
        </motion.h1>
      )}
    </div>
  );
};

export default FlipsideAnimation;
