
import { useTranslation } from "react-i18next";
import Session from "../componet/Session/Session";

const Meeting = () => {
  const {t} = useTranslation()
  return (
    <div>
      <Session />
    </div>
  );
};

export default Meeting;
