import { useTranslation } from "react-i18next"
import "./Thanks.css"

const Thanks = () => {
    const {t} = useTranslation()
  return (
    <h2 className="thanks">  {t("thanks")} </h2>
  )
}

export default Thanks